import axios from "axios";
import ls from "local-storage";
const API_KEY =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PROD_API_URL;

const instance = axios.create({
  baseURL: API_KEY,
});
async function reFetch(originalRequest) {
  const response = await axios({
    method: originalRequest.method,
    url: API_KEY + originalRequest.url,
    headers: { Authorization: "Bearer " + ls.get("sfToken") },
  });
  return response;
}
async function reFetchData(originalRequest) {
  const response = await axios({
    method: originalRequest.method,
    url: API_KEY + originalRequest.url,
    data: originalRequest.data,
    headers: { Authorization: "Bearer " + ls.get("sfToken") },
  });
  return response;
}

async function reFetchToken(response) {
  const responseT = await axios({
    method: "post",
    url: API_KEY + "auth/reCreate.php",
    headers: { Authorization: "Bearer " + ls.get("sfToken") },
  });
  if (responseT.data.status === 200) {
    ls.remove("sfToken");
    ls.set("sfToken", responseT.data.token);
    const originalRequest = response.config;
    originalRequest.headers["Authorization"] = "Bearer " + responseT.data.token;

    if (originalRequest.data === null || originalRequest.data === undefined) {
      const response = await reFetch(originalRequest);
      return response;
    } else {
      const response = await reFetchData(originalRequest);
      return response;
    }
  }
}

instance.defaults.timeout = 1000000;
instance.interceptors.request.use((config) => {
  config.headers = {};
  if (ls.get("sfToken")) {
    config.headers["Authorization"] = "Bearer " + ls.get("sfToken");
  } else {
    config.headers["Authorization"] =
      "Bearer V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=";
  }
  return config;
});
instance.interceptors.response.use(
  async function (response) {
    if (
      response.data.status === 401 ||
      response.status !== 200 ||
      response === undefined
    ) {
      const ress = await reFetchToken(response);
      return ress;
    } else {
      return response;
    }
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      const ress = await reFetchToken(error);
      return ress;
    }
  }
);
export default instance;

import React from 'react'

const SkeletonOnboard = () => {
    return (
        <div
            style={{
                width: 300,
                height: "100vh",
                position: 'relative',
                overflow: 'hidden',
                display: "flex",
                alignItems: "center"
            }}
        >
            <img
                alt="ur"
                src="/assets/img/logo/ur-biru.webp"
                style={{
                    borderRadius: 10,
                    width: 300,
                    height: 300,
                    objectFit: 'cover',
                    objectPosition: 'center',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
            />
        </div>
    )
}

export default SkeletonOnboard
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const userLogin = (userDetail) => {
    return (dispatch) => {
        dispatch({
            type: USER_LOGIN,
            payload: {
                ...userDetail
            }
        })
    }
}

export const userLogout = () => {
    return (dispatch) => {
        dispatch({
            type: USER_LOGOUT,
            payload: {}
        })
    }
}
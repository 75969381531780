import "./App.css";
import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Screen404 from "./views/404";
import OnboardingScreen from "./views/Onboarding";
import OrderScreen from "./views/Order";
import ProductDetailsScreen from "./views/ProductDetails";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@emotion/react";
import SearchScreen from "./views/Search";
import CartScreen from "./views/Cart";
import { connect } from "react-redux";
import HistoryScreen from "./views/History";
import SendOTPScreen from "./views/SendOTP";
import VerifyOTPScreen from "./views/VerifyOTP";
import CompleteProfileScreen from "./views/CompleteProfile";
import { grey } from "@mui/material/colors";
import HistoryDetailScreen from "./views/HistoryDetail";
import OrderSuccess from "./views/OrderSuccess";
import ItemDetails from "./views/ItemDetails";
import Payment from "./views/Payment";
import Success from "./views/Success";
import ReviewList from "./views/ReviewList";
import QrisUr from "./views/QrisUr";
import CreateReviewScreen from "./views/CreateReview";
import { MembershipScreen } from "./views/Membership";
import OrderReceivedScreen from "./views/OrderReceived";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const theme = createTheme({
  palette: {
    primary: {
      main: "#30412F",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    red: {
      main: "#D22B2B",
    },
    grey: grey,
  },
  typography: {
    fontFamily: `"Rubik"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});
class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Routes>
              <Route path="/" element={<OnboardingScreen />} />
              <Route path="/order" element={<OrderScreen />} />
              <Route path="/auth" element={<SendOTPScreen />} />
              <Route path="/verify-otp" element={<VerifyOTPScreen />} />
              <Route path="/history" element={<HistoryScreen />} />
              <Route path="/history-detail" element={<HistoryDetailScreen />} />
              <Route
                path="/complete-profile"
                element={<CompleteProfileScreen />}
              />
              <Route
                path="/product-details"
                element={<ProductDetailsScreen />}
              />
              <Route path="/item-details" element={<ItemDetails />} />
              <Route path="/search" element={<SearchScreen />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/success" element={<Success />} />
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route path="/review-list" element={<ReviewList />} />
              <Route path="/qris-ur" element={<QrisUr />} />
              <Route path="/create-review" element={<CreateReviewScreen />} />
              <Route path="/membership" element={<MembershipScreen />} />
              <Route path="/order-received" element={<OrderReceivedScreen />} />
              <Route
                path="/404"
                element={(props) => <Screen404 {...props} />}
              />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default connect()(App);

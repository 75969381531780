import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StarRateIcon from '@mui/icons-material/StarRate';

const ReviewCard = ({ data }) => {

    const [width, setWindowWidth] = useState(0);
    const [dateReview, setDateReview] = useState("");
    const [starIcons, setStarIcons] = useState([]);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    useEffect(() => {
        updateDimensions();

        let icons = [];
        if (data) {
            for (let i = 0; i < parseFloat(data.rating); i++) {
                icons.push(
                    <StarRateIcon
                        key={i}
                        style={{
                            color: "#FFDF00",
                            marginLeft: -2,
                            fontSize: 20
                        }}
                    />
                );
            }

            setStarIcons(icons)
            const date = new Date(data.created_at)

            const formattedDate = date.toLocaleDateString('en-GB', {
                day: 'numeric', month: 'short', year: 'numeric'
            })

            setDateReview(formattedDate)
        }

        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [data])

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    return (
        <Card
            sx={{
                width: width >= 480 ? '100%' : width * 90 / 100,
                minHeight: 120
            }}
        >
            <CardContent
                className="content"
                style={{
                    padding: 15,
                    display: 'flex',
                    minHeight: 120,
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <Typography variant="body2" style={{overflowWrap:"break-word"}}>
                    {data.review}
                </Typography>
                <div>
                    <div>
                        {starIcons}
                    </div>
                    <Typography
                        style={{
                            fontSize: 14
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 500
                            }}
                        >
                            {data.anonymous === "1" ? "Anonim" : data.name} {bull}
                        </span>
                        <span
                            style={{
                                color: "#6c757d"
                            }}
                        >
                            {dateReview}
                        </span>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}

export default ReviewCard
import React from "react";
import { NumericFormat } from "react-number-format";
export default function MoneyFormat(props) {
  return (
    <NumericFormat
      value={props.value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"Rp "}
    />
  );
}

import instance from "../axios";

export const getByPartnerID = async (id) => {
    const response = await instance.get("reviews/GetByPartnerID.php?id=" + id);
  
    return response.data;
  };

export const getAllByPartnerID = async (id, transactionID) => {
    const response = await instance.get("reviews/GetAllByPartnerIDWithTransaction.php?id=" + id + "&transactionID=" + transactionID);
  
    return response.data;
  };
import React, { useState, useEffect, useRef, createContext } from "react";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import DefaultContainer from "../components/DefaultContainer";
import { Button, Grid, Typography, Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ls from "local-storage";
import { getStatus } from "../services/Transactions";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { deleteAllFromCart } from "../config/redux/actions/cartActions";
import { connect } from "react-redux";
import { PAYMENT_METHOD, isPaymentHandled } from "../constants/paymentMethods";
import { TRANSACTION_STATUS } from "../constants/transactionStatus";

export const DynamicContext = createContext();

const Success = ({ deleteAllFromCart, cartData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [isOvoExpired, setIsOvoExpired] = useState(false);
  const [transcStatus, setTranscStatus] = useState("0");
  const [queue, setQueue] = useState("");
  const [renderCounter, setRenderCounter] = useState(0);
  const [isMembership, setIsMembership] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const [dynamicWidth, setDynamicWidth] = useState(0);
  const [dynamicRatio, setDynamicRatio] = useState(0);
  const [isSmallPhone, setIsSmallPhone] = useState(false);

  // const dynamicHeightRef = useRef()

  useEffect(() => {
    if (location?.state?.urlResponse && renderCounter === 0) {
      const urlResponse = location.state.urlResponse ?? ls.get("urlResponse");
      window.open(urlResponse, "_blank");
      setRenderCounter(renderCounter + 1);
    }
    const fetchStatus = async () => {
      const response = await getStatus(
        query.transactionID,
        ls.get("redux_localstorage_simple").userData[0].phone
      );
      if (response.success === 1) {
        setIsMembership(response.isMembership);
        // if (response.transactionStatus === query.status) {
        setPaymentMethod(response.paymentMethod);
        setPaymentMethodName(response.paymentMethodName);
        if (
          response.id.includes("-") &&
          response.updatedAt &&
          (response.paymentMethod === PAYMENT_METHOD.OVO ||
            response.paymentMethod === 1)
        ) {
          setCreatedAt(response.updatedAt);
        } else {
          setCreatedAt(response.createdAt);
        }
        setQueue(response.queue);
        if (response.transactionStatus) {
          if (response.transactionStatus === TRANSACTION_STATUS.notPaid) {
            setStatus(false);
            if (location?.state?.urlResponse) {
              ls.set("urlResponse", location.state.urlResponse);
              ls.set("transactionID", query.transactionID);
            }
          } else if (
            response.transactionStatus ===
            TRANSACTION_STATUS.cancelledByCustomer
          ) {
            setStatus(false);
            setTranscStatus(TRANSACTION_STATUS.cancelledByCustomer);
          } else if (
            response.transactionStatus === TRANSACTION_STATUS.cancelledByCashier
          ) {
            setStatus(false);
            setTranscStatus(TRANSACTION_STATUS.cancelledByCashier);
          } else {
            if (ls.get("urlResponse")) {
              ls.remove("urlResponse");
              ls.remove("transactionID");
            }
            setStatus(true);
            setTranscStatus("2");
            if (cartData && cartData.length > 0) {
              deleteAllFromCart();
            }
          }
        } else {
          setStatus(false);
        }
      }
      setLoading(false);
    };

    if (query) {
      fetchStatus();
    }

    if (
      dynamicRatio < 1.8 &&
      dynamicHeight !== 0 &&
      dynamicHeight > dynamicWidth &&
      dynamicHeight < 560 &&
      dynamicWidth < 380
    ) {
      setIsSmallPhone(true);
      console.log({ dynamicRatio, dynamicHeight, dynamicWidth });
    } else {
      setIsSmallPhone(false);
    }

    ovoPaymentTimer();
  }, [query]);

  const handleSuccess = () => {
    navigate("/history-detail?transactionID=" + query.transactionID, {
      state: {
        to: "/history-detail?transactionID=" + query.transactionID,
        phone: ls.get("redux_localstorage_simple")?.userData[0]?.phone ?? "",
        isMembership: isMembership,
      },
    });
  };

  const handleFail = () => {
    navigate("/payment", { state: { transactionID: query.transactionID } });
  };

  const handleBack = () => {
    navigate("/order", { state: location.state });
  };

  const handleRefresh = () => {
    if (paymentMethod !== "2") {
      window.location.reload();
    } else {
      navigate(`.${location.search}`, { state: null });
    }
  };

  const ovoPaymentTimer = () => {
    const transactionCreated = new Date(createdAt);
    const timeNow = new Date();
    const difference = parseInt((timeNow - transactionCreated) / 1000);
    const ovoPaymentExpired = 55;

    if (difference > ovoPaymentExpired) {
      setIsOvoExpired(true);
    }
  };

  return (
    // <div ref={dynamicHeightRef}>
    <DefaultContainer
      bgColor={"primary.main"}
      noBanner={true}
      isContext={true}
      isContextProps={{
        setDynamicHeight,
        setDynamicWidth,
        setDynamicRatio,
        isSmallPhone,
      }}
    >
      {loading ? (
        <Box
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            position: "relative",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Box
          style={
            isSmallPhone
              ? {
                  height: "100vh",
                  display: "flex",
                  color: "#fff",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }
              : {
                  // height: isSmallPhone  ? 'auto' : '100vh',
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  position: "relative",
                }
          }
        >
          <Grid
            style={{
              width: 100,
              height: isSmallPhone ? "auto" : 100,
              marginTop: 0,
              borderRadius: "50%",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#30412F",
            }}
          >
            {status ? (
              <DoneIcon
                style={{
                  fontSize: 80,
                  marginTop: isSmallPhone ? 10 : 0,
                }}
              />
            ) : ((paymentMethod === PAYMENT_METHOD.OVO && !isOvoExpired) ||
                isPaymentHandled(paymentMethod) ||
                ((ls.get("sfPartner").open_bill === 0 ||
                  ls.get("sfPartner").open_bill === "0") &&
                  paymentMethod === PAYMENT_METHOD.pending)) &&
              transcStatus !== TRANSACTION_STATUS.cancelledByCustomer &&
              transcStatus !== TRANSACTION_STATUS.cancelledByCashier ? (
              <HourglassTopIcon
                style={{
                  fontSize: 70,
                }}
              />
            ) : (
              <CloseIcon
                style={{
                  fontSize: 70,
                }}
                color="warning"
              />
            )}
          </Grid>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 500,
              marginTop: 10,
            }}
          >
            {status
              ? `Pembayaran sudah diterima`
              : ((paymentMethod === PAYMENT_METHOD.OVO && !isOvoExpired) ||
                  isPaymentHandled(paymentMethod) ||
                  ((ls.get("sfPartner").open_bill === 0 ||
                    ls.get("sfPartner").open_bill === "0") &&
                    paymentMethod === PAYMENT_METHOD.pending)) &&
                transcStatus !== TRANSACTION_STATUS.cancelledByCustomer &&
                transcStatus !== TRANSACTION_STATUS.cancelledByCashier
              ? "Menunggu pembayaran"
              : transcStatus === TRANSACTION_STATUS.cancelledByCustomer ||
                transcStatus === TRANSACTION_STATUS.cancelledByCashier
              ? "Pesanan Dibatalkan"
              : "Gagal melakukan pembayaran"}
          </Typography>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 300,
              padding: "0px 40px",
              textAlign: "center",
            }}
          >
            {status
              ? "Silahkan tunggu pesanan kamu, terima kasih"
              : paymentMethod === PAYMENT_METHOD.OVO && !isOvoExpired
              ? "Silahkan lakukan pembayaran di aplikasi OVO kamu. JIka pembayaran sudah dilakukan silahkan refresh halaman ini"
              : (paymentMethod === PAYMENT_METHOD.gopay ||
                  paymentMethod === PAYMENT_METHOD.dana ||
                  paymentMethod === PAYMENT_METHOD.linkAja ||
                  paymentMethod === PAYMENT_METHOD.shopeePay ||
                  paymentMethod === PAYMENT_METHOD.qrisUR) &&
                transcStatus !== TRANSACTION_STATUS.cancelledByCustomer &&
                transcStatus !== TRANSACTION_STATUS.cancelledByCashier
              ? `Silahkan lakukan pembayaran melalui ${paymentMethodName} kamu. JIka pembayaran sudah dilakukan silahkan refresh halaman ini`
              : paymentMethod === PAYMENT_METHOD.tunai ||
                paymentMethod === PAYMENT_METHOD.creditCard ||
                paymentMethod === PAYMENT_METHOD.debitCard ||
                paymentMethod === PAYMENT_METHOD.qris
              ? "Silahkan lakukan pembayaran pesanan kamu di kasir. Jika pembayaran sudah dilakukan silahkan refresh halaman ini"
              : transcStatus === TRANSACTION_STATUS.cancelledByCashier
              ? "Pesanan kamu telah dibatalkan oleh kasir"
              : transcStatus === TRANSACTION_STATUS.cancelledByCustomer
              ? "Pesanan kamu telah berhasil dibatalkan"
              : (ls.get("sfPartner").open_bill === 0 ||
                  ls.get("sfPartner").open_bill === "0") &&
                paymentMethod === PAYMENT_METHOD.pending
              ? "Silahkan lakukan pembayaran pesanan kamu di kasir. Jika pembayaran sudah dilakukan silahkan refresh halaman ini"
              : "Silahkan lakukan pembayaran ulang untuk menyelesaikan transaksi kamu"}
          </Typography>
          {status && ls.get("sfPartner").is_queue_tracking === "1" ? (
            <Grid
              style={{
                marginTop: 20,
                width: 100,
                height: 100,
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "black",
                color: "#30412F",
                flexWrap: "wrap",
                alignContent: "center",
                padding: "30px 0px 30px 0px",
              }}
            >
              <Typography
                style={{
                  fontSize: 20,
                  color: "#30412F",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Antrian:
              </Typography>
              <Typography
                style={{
                  fontSize: 40,
                  color: "#30412F",
                  width: "full",
                }}
              >
                {queue}
              </Typography>
            </Grid>
          ) : (
            <div></div>
          )}
          {!status ? (
            ((paymentMethod === PAYMENT_METHOD.OVO && !isOvoExpired) ||
              isPaymentHandled(paymentMethod) ||
              ((ls.get("sfPartner").open_bill === 0 ||
                ls.get("sfPartner").open_bill === "0") &&
                paymentMethod === PAYMENT_METHOD.pending)) &&
            transcStatus !== TRANSACTION_STATUS.cancelledByCustomer &&
            transcStatus !== TRANSACTION_STATUS.cancelledByCashier ? (
              <Grid
                style={{
                  width: 200,
                  marginTop: 30,
                }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  color="white"
                  onClick={() => handleRefresh()}
                >
                  <Typography style={{ padding: 5 }}>Refresh</Typography>
                </Button>

                {location?.state?.urlResponse ? (
                  <Button
                    style={{ marginTop: "10px" }}
                    fullWidth
                    variant="outlined"
                    color="white"
                    onClick={() =>
                      window.open(location.state.urlResponse, "_blank")
                    }
                  >
                    Lanjutkan Pembayaran
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
            ) : transcStatus !== TRANSACTION_STATUS.cancelledByCashier &&
              transcStatus !== TRANSACTION_STATUS.cancelledByCustomer ? (
              <Grid
                style={{
                  width: 200,
                  marginTop: 30,
                }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  color="white"
                  onClick={() => handleFail()}
                >
                  <Typography style={{ padding: 5 }}>Bayar Ulang</Typography>
                </Button>
              </Grid>
            ) : (
              <></>
            )
          ) : (
            <>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 300,
                  padding: "0px 40px",
                  textAlign: "center",
                  marginTop: 30,
                }}
              >
                Sambil menunggu pesanan kamu datang, nih ada wifi buat kamu
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 300,
                  padding: "0px 40px",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                SSID:{" "}
                <span style={{ fontWeight: 500 }}>
                  {ls.get("sfPartner").wifi_ssid}
                </span>
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 300,
                  padding: "0px 40px",
                  textAlign: "center",
                }}
              >
                Password:{" "}
                <span style={{ fontWeight: 500 }}>
                  {ls.get("sfPartner").wifi_password}
                </span>
              </Typography>
              <Grid
                item
                container
                style={{
                  paddingRight: 15,
                  paddingLeft: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  position: isSmallPhone ? "relative" : "fixed",
                  bottom: 0,
                  maxWidth: 480,
                }}
              >
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="white"
                    onClick={() => handleSuccess()}
                  >
                    <Typography style={{ padding: 5 }}>OK</Typography>
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {(transcStatus === TRANSACTION_STATUS.cancelledByCashier ||
            transcStatus === TRANSACTION_STATUS.cancelledByCustomer) && (
            <Grid
              item
              style={{ position: "absolute", bottom: 15, width: "50%" }}
            >
              <Button
                variant="outlined"
                color="white"
                fullWidth
                onClick={() => handleBack()}
              >
                <Typography style={{ padding: 5 }}>Kembali</Typography>
              </Button>
            </Grid>
          )}
        </Box>
      )}
    </DefaultContainer>
    // </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData.filter(
      (data) => data.partnerID === ls.get("partnerID")
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);

import React, { useEffect, useState } from 'react'
import DefaultContainer from "../components/DefaultContainer";
import DefaultButton from "../components/DefaultButton";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const OrderSuccess = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [counter, setCounter] = useState(5);

    useEffect(() => {
        if (location) {
            if (!location.state) {
                navigate(-1)
            }
            timer()
            setTimeout(() => {
                navigate(location.state.from)
            }, 5000)
        }
    }, [location, navigate])

    function timer() {
        if (counter > 0) {
            setInterval(() => {
                setCounter((counter) => counter - 1);
            }, 1000);
        }
    }

    return (
        <DefaultContainer>
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <div
                    style={{
                        // border: '2px solid black',
                        width: '100%',
                        height: 300
                    }}
                >
                    <img
                        alt=""
                        src="/assets/img/success-order.png"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
                <Typography
                    style={{
                        fontSize: 20
                    }}
                >
                    Pesanan anda telah diterima
                </Typography>
                <Typography
                    style={{
                        fontSize: 16,
                        marginTop: 15,
                        padding: '0px 15px',
                        textAlign: 'center'
                    }}
                >
                    Halaman akan kembali ke home dalam
                    {" "}
                    <span style={{ fontWeight: 'bold' }}>{counter}</span>
                    {" "}
                    detik atau klik tombol di bawah
                </Typography>
                <div
                    style={{
                        width: 200
                    }}
                >
                    <DefaultButton
                        title="Kembali"
                        onClick={
                            () => navigate(-1)
                        }
                    />
                </div>
            </div>
        </DefaultContainer>
    )
}

export default OrderSuccess
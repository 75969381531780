import instance from "../axios";

export const insertOTP = async (data) => {
  const response = await instance.post("auth/SendOTP.php", data);

  return response.data;
};

export const OTPValidation = async () => {
  const response = await instance.get("utilities/GetOTPValidation.php");

  return response.data;
};
export const validateOTP = async (data) => {
  const response = await instance.post("auth/ValidateOTP.php", data);

  return response.data;
};
export const register = async (dataRegist) => {
  const response = await instance.post("auth/Register.php", JSON.stringify(dataRegist));

  return response.data;
};


import { Badge, Card, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DefaultContainer from "../components/DefaultContainer";
import DefaultHeader from "../components/DefaultHeader";
import { getHistory } from "../services/Transactions";
import MoneyFormat from "../components/MoneyFormat";
import { useLocation, useNavigate } from "react-router-dom";
import ls, { off } from "local-storage";
import moment from "moment";
import 'moment/locale/id';
import { getByID } from "../services/Partners";
import {Button} from "@mui/material";

export default function HistoryScreen() {
  const navigate = useNavigate();
  const location = useLocation()
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const partnerID = urlParams.get('partnerID')

  async function fetchPartnerDetail(id) {
    await getByID(id).then((response) => {
      if (response.partners.length > 0) {
        ls.set("sfPartner", response.partners[0]);
        ls.set("sfHours",response.openingHour[0]);
      }
      setLoading(false);
    });
  }

  useEffect(() => {

    if(ls.get("sfToken") === null 
    || ls.get("sfToken").includes("dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz")
    || ls.get("sfToken").includes("V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")
    ){
      ls.remove("redux_localstorage_simple")
      navigate("/auth", {
        replace: true,
        state: { to: "/history" },
      })
    } else {
      if(ls.get("redux_localstorage_simple")){
        fetchHistory();
        if(ls.get("sfPartner") === null){
          fetchPartnerDetail(ls.get("partnerID") ? ls.get("partnerID") : partnerID)
        }  
      }else{
        setHistory([])
        navigate(`/auth?partnerID=${partnerID}`, { state: { to: "/order", from:"/history" } });
      }
    }
  }, []);

  async function fetchHistory() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const partnerID = urlParams.get('partnerID')
    setLoading(true);
    await getHistory({ partnerID: ls.get("partnerID") ? ls.get("partnerID") : partnerID  }).then((response) => {
      setHistory(response["transactions"]);
      setLoading(false);
    });
  }

  let data =
    history &&
    history.map((x, i) => {
      return (
        <Card
          key={i}
          style={{ marginBottom: 15, padding: 15 }}
          onClick={(e) => {
            navigate(`/history-detail?transactionID=${history[i].id}&partnerID=${partnerID}`, { state: { details: history[i]}});
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                {(moment(history[i].jam).locale('id').format('LLL')).replace('pukul', " ")} <br/> {history[i].id}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <MoneyFormat value={Math.ceil(history[i].rounding)} />
            </Grid>
            {
             history[i].status === "3" || history[i].status === 3 ?
              <Grid xs={6} marginLeft={"60px"}>
                <Badge color="error" anchorOrigin={{vertical:"top", horizontal:"left"}} sx={{width:"150px"}} badgeContent={<Typography style={{fontSize:"12px"}}>Dibatalkan Customer</Typography>}/>
              </Grid> 
            : history[i].status === "4" || history[i].status === 4 ?
              <Grid xs={6} marginLeft={"46px"}>
                <Badge color="error" anchorOrigin={{vertical:"top", horizontal:"left"}} sx={{width:"150px"}} badgeContent={<Typography style={{fontSize:"12px"}}>Dibatalkan Kasir</Typography>}/>
              </Grid> 
            : history[i].pmName === "PENDING" || history[i].status === "0" || history[i].status === 0?
              <Grid xs={6} marginLeft={"42px"}>
                <Badge color="error" anchorOrigin={{vertical:"top", horizontal:"left"}} sx={{width:"100px"}} badgeContent={<Typography style={{fontSize:"12px"}}>Belum Dibayar</Typography>}/>
              </Grid> :
              <></>
            }
          </Grid>
        </Card>
      );
    });

  console.log(data.length)

  return (
    <DefaultContainer loading={loading}>
      <Grid style={{ margin: 15, textAlign: "left" }}>
        <DefaultHeader
          title="Riwayat"
          url={`/order?partnerID=${ls.get("partnerID") ? ls.get("partnerID") : partnerID}`}
        />
        <Grid container style={{ marginTop: 15 }}>
          <Grid item xs={12}>
            {loading ? (
              <center>
                {/* //buat shimmer card */}
                <CircularProgress />
              </center>
            ) : history ? (
              data.length > 0 ?
              data :
              <div style={{height:"100%", textAlign:"center", maxWidth:"100%"}}>
                <div style={{zIndex:"-1", position:"relative",display:"flex", flexWrap:"wrap", height:"80%", width:"100%", justifyContent:"center", alignItems:"center", alignContent:"center", overflowX:"hidden"}}>
                  <div style={{width:"100%", textAlign:"center" }}>
                    <img style={{color:"silver", width:"60%", marginBottom:"2vh" }} src={"/assets/img/empty_map.png"}/>
                  </div>
                  <div style={{color:"silver", width:"60%", textAlign:"center"}}>Tidak Ada Transaksi</div>
                </div>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      borderRadius: 10,
                      marginTop:sessionStorage.getItem("comparison") < 0.5 ? sessionStorage.getItem("height")*0.39 : sessionStorage.getItem("comparison") > 1 ? sessionStorage.getItem("height")*0.33 : sessionStorage.getItem("comparison") > 0.74 ? sessionStorage.getItem("height")*0.34 : sessionStorage.getItem("height")*0.35,
                      // background: buttonBehavior.disabled === false && addToCartClick === false ? buttonBehavior.bgColor : "gray"
                    }}
                    onClick={()=>{
                      navigate("/order", {state: {...location.state}})
                    }}
                  >
                    Pesan Sekarang
                  </Button>
              </div>
            ) : (
              <div style={{height:"100%"}}>
                <div style={{color:"silver"}}>Tidak Ada Transaksi</div>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DefaultContainer>
  );
}

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Confirmation = ({ open, onClose, title, confirmMsg, onOk, onlyOk }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Dialog
        open={open}
        onClose={onClose}
        style={{
          maxWidth: 480,
          margin: "0px auto",
        }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {!onlyOk && (
            <Button
              onClick={onClose}
              style={{
                color: "#000",
              }}
            >
              Batal
            </Button>
          )}
          <Button
            onClick={onOk}
            style={{
              color: "#30412F",
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Confirmation;

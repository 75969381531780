import React from 'react'
import { Stack, Skeleton, Grid } from '@mui/material';

const SkeletonOrder = () => {
    return (
        <Stack
            spacing={1}
            style={{
                padding: 15,
                height: '100%'
            }}
        >
            <Grid
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Skeleton variant="rounded" width={50} height={50} />
                <Skeleton variant="rounded" width={200} height={50} />
                <Skeleton variant="rounded" width={50} height={50} />
            </Grid>
            <Grid
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 30
                }}
            >
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={50} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={50} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={50} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={50} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={50} />
            </Grid>
            <Grid
                style={{
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15
                }}
            >
                <Grid container>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={75} height={75} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={75} height={75} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={75} height={75} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={75} height={75} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={75} height={75} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={75} height={75} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <Skeleton variant="rounded" width={75} height={75} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={70} />
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default SkeletonOrder
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from 'qrcode.react';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import ls from "local-storage";
import qs from "query-string";
import {
    Box,
    Grid,
    Typography,
    Button
} from "@mui/material";
import { getStatus } from "../services/Transactions";
import DefaultContainer from "../components/DefaultContainer";
import DefaultHeader from '../components/DefaultHeader';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoneyFormat from "../components/MoneyFormat";

const QrisUr = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const [status, setStatus] = useState("");
    const [isExpand, setIsExpand] = useState(false);

    const fetchStatus = async (id) => {
        const response = await getStatus(id);
        if (response.success === 1) {
            setStatus({
                createdAt: response['created_at'],
                id: response['id'],
                paymentMethod: response['paymentMethod'],
                paymentMethodname: response['paymentMethodName'],
                qrString: response['qrString'],
                transcStatus: response['transactionStatus'],
                total: response['total']
            })
        }
    }

    useEffect(() => {
        if (Object.keys(query).length > 0) {
            fetchStatus(query.transactionID)
        } else {
            navigate(-1)
        }
    }, [])
    const saveImage = () => {
        setTimeout(() => {
            const name = 'UR_' + ls.get('partnerID') + '_qris-ur.png';
            domtoimage.toBlob(document.querySelector("#qrSave"))
                .then(function (blob) {
                    saveAs(blob, name);
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        }, 3000); // Delay of 3000 milliseconds (3 seconds)
    };

    // const saveImage = () => {
    //     var name = 'UR_' + ls.get('partnerID') + '_qris-ur.png';
    //     domtoimage.toBlob(document.querySelector("#qrSave"))
    //         .then(function (blob) {
    //             saveAs(blob, name);
    //         })
    //         .catch(function (error) {
    //             console.error('oops, something went wrong!', error);
    //         });
    // }

    const expand = () => {
        setIsExpand(!isExpand)
    }

    const handleClick = async () => {
        const response = await getStatus(query.transactionID);

        if (response.success === 1) {
            if (response['transactionStatus'] === "1" || response['transactionStatus'] === "2") {
                navigate(
                    `/history-detail?transactionID=${response['id']}`
                )
            } else {
                window.location.reload();
            }
        } else {
            window.location.reload();
        }
    }

    return (
        <DefaultContainer
            bgColor={"primary.main"}
        >
            <Box
                className="qris-ur"
                style={{
                    background: '#30412F'
                }}
            >
                <Grid>
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                            style={{
                                background: "#30412F",
                                padding: 15,
                                textAlign: "center",
                                position: "sticky",
                                top: 0,
                                zIndex: 2
                            }}
                        >
                            <DefaultHeader
                                title={"QRIS"}
                                bgColor="#30412F"
                                url={
                                    `/history-detail?transactionID=${status.id}`
                                }
                            />
                        </Grid>
                        <Grid
                            style={{
                                paddingTop: 30,
                                width: '100%',
                                maxHeight: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                id="qrSave"
                                style={{
                                    position: 'relative',
                                    height: 235,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <QRCode
                                    id=""
                                    value={status.qrString}
                                    renderAs='svg'
                                    size={225}
                                    level={"H"}
                                    includeMargin={true}
                                    style={
                                        {
                                            position: 'relative',
                                            zIndex: 1,
                                            borderRadius: 5,
                                        }
                                    }
                                />
                                <div
                                    style={{
                                        width: 45,
                                        height: 45,
                                        position: 'absolute',
                                        zIndex: 2
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/assets/img/ur-qris.png"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 15
                            }}
                        >
                            <Typography
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5,
                                    color: '#fff',
                                    fontSize: 12
                                }}
                                onClick={() => saveImage()}
                            >
                                <FileDownloadOutlinedIcon />
                                <span>Download</span>
                            </Typography>
                        </Grid>
                        <Grid
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 30
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: 24,
                                    fontWeight: 500,
                                    color: '#fff'
                                }}
                            >
                                <MoneyFormat
                                    value={status ? status.total : 0}
                                />,-
                            </Typography>
                        </Grid>
                        <Grid
                            style={{
                                padding: '0px 15px',
                                marginTop: 30,
                                color: '#fff'
                            }}
                        >
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    lineHeight: 1.15,
                                    fontSize: 14
                                }}
                            >
                                Silakan melakukan pembayaran
                                melalui aplikasi yang anda inginkan.
                            </Typography>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    fontSize: 14
                                }}
                            >
                                Berikut ini cara nya :
                            </Typography>
                        </Grid>
                        <Grid
                            style={{
                                padding: '0px 15px',
                                marginTop: 5,
                                color: '#fff',
                                width: '100%'
                            }}
                        >
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    fontSize: 14
                                }}
                            >
                                <KeyboardArrowDownIcon
                                    onClick={() => expand()}
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            style={{
                                padding: '0px 15px',
                                marginTop: 10,
                                color: '#fff',
                                transition: "0.3s",
                                width: "100%",
                                height: isExpand ? 133 : 0,
                                overflow: 'hidden'
                            }}
                        >
                            <ol
                                style={{
                                    fontSize: 14
                                }}
                            >
                                <li>
                                    <Typography
                                        style={{ fontSize: 14 }}
                                    >
                                        Download atau screenshot qr ini
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        style={{ fontSize: 14 }}
                                    >
                                        Buka aplikasi e-wallet atau mobile banking pilihan kalian
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        style={{ fontSize: 14 }}
                                    >
                                        Pilih fitur pembayaran menggunakan QRIS
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        style={{ fontSize: 14 }}
                                    >
                                        Lalu upload dari galeri
                                    </Typography>
                                </li>
                            </ol>
                        </Grid>
                        <Grid
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: 14,
                                    color: '#fff'
                                }}
                            >
                                Sudah melakukan pembayaran?
                            </Typography>
                            <Button
                                variant="outlined"
                                color="white"
                                style={{
                                    width: '30%',
                                    margin: '15px 0px'
                                }}
                                onClick={() => {
                                    handleClick()
                                }}
                            >
                                Klik disini
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </DefaultContainer>
    )
}

export default QrisUr
import { Button } from "@mui/material";
import React from "react";

export default function DefaultButton({ title, onClick, disabled }) {
  return (
    <Button
      variant="contained"
      fullWidth
      disabled={disabled ? disabled : false}
      style={{ color: "white", marginTop: 15, padding: 10 }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

import instance from "../axios";

export const getReviewContent = async (transactionID) => {
  const response = await instance.get(
    "reviews/GetReviewContent.php?transactionID=" + transactionID
  );

  return response.data;
};

export const reviewInsert = async (data) => {
  const response = await instance.post("reviews/Insert.php", data);

  return response.data;
};

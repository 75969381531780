import { Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ls from "local-storage";


export default function DefaultHeader({ title, trailing, url, bgColor, specialActions }) {
  const navigate = useNavigate();

  function back() {
    ls.remove("sfReview")

    if (url && url !== "reload") {
      navigate(url);
    } else {
      if(specialActions === "payment"){
        navigate("/cart", {state: {}})
      } else {
        navigate(-1);
      }
    }
  }
  return (
    <Grid
      container
      style={{
        backgroundColor: bgColor ? bgColor : "#fff",
      }}
    >
      <Grid
        item
        xs={1}
        onClick={(e) => {
          if(url === "reload"){
            return
          }else {
            back();
          }
        }}
        style={{
          display: "flex",
          justifyContents: "center",
          alignItems: "center",
          color: bgColor ? "#fff" : "",
        }}
      >
        {
        url === "reload" ?
        <div></div>
        :
        <ArrowBackOutlinedIcon />
        }
      </Grid>
      <Grid item xs={trailing ? 9 : 11}>
        <Typography
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: 20,
            fontWeight: "500",
            color: bgColor ? "#fff" : "",
          }}
          textAlign="left"
        >
          {title}
        </Typography>
      </Grid>
      {trailing ? (
        <Grid item xs={2} textAlign="right">
          {trailing}
        </Grid>
      ) : (
        <div></div>
      )}
    </Grid>
  );
}

import React from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Warning = ({ msg }) => {
    return (
        <Stack
            sx={{ width: '100%' }}
            spacing={2}
            style={{
                position: 'sticky',
                top: 0,
                right: "auto",
            }}
        >
            <Alert
                severity="warning"
                style={{
                    background: '#FFF4E5',
                    color: '#6E450C'
                }}
            >
                {msg}
            </Alert>
        </Stack>
    )
}

export default Warning
import { TextField } from "@mui/material";
import React from "react";

export default function DefaultTextField({
  error,
  autoComplete,
  label,
  id,
  variant,
  fullWidth,
  autoFocus,
  helperText,
  value,
  onChange,
  type,
}) {
  return (
    <TextField
      sx={{
        "& .MuiOutlinedInput-root": {
          "& > fieldset": { borderColor: "GrayText" },
        },
      }}
      error={error}
      autoComplete={autoComplete}
      label={label}
      id={id}
      variant={variant ? variant : "outlined"}
      fullWidth={fullWidth ? fullWidth : true}
      autoFocus={autoFocus ? autoFocus : false}
      helperText={helperText}
      value={value}
      onChange={onChange}
      type={type ?? "text"}
    />
  );
}

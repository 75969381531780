import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DefaultContainer from "../components/DefaultContainer";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultTextField from "../components/DefaultTextField";
import DefaultHeader from "../components/DefaultHeader";
import DefaultButton from "../components/DefaultButton";
import { register } from "../services/Auth"
import Toast from "../components/Snackbar";
import { userLogin } from "../config/redux/actions/userActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useEffect } from "react";
import { validateOTP } from "../services/Auth";
import ls from "local-storage";
function CompleteProfileScreen({ userLogin }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [wantMember, setWantMember] = useState(false);
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");
  const [formErrors, setFormErrors] = useState({})

  // function back() {
  //   navigate(-1, { replace: true });
  // }  

  async function registerUser(dataRegist) {
    const response = await register(dataRegist);

    if (response.success === 1) {
      setIsOpen(true)
      setMsg(response.msg)
      setStatus(response.success)
      ls.set("sfToken", response.token)

      clearDataRegist()

      setTimeout(() => {
        navigate(location.state.to)
      }, 1000)
    } else {
      setIsOpen(true)
      setMsg(response.msg)
      setStatus(response.success)
      setTimeout(() => {
        setIsOpen(false)
      }, 100)
    }

  }

  const clearDataRegist = () => {
    setName('')
    setEmail('')
    setBirthDate('')
    setGender('')
  }

  const formValidation = async () => {
    let values = {}

    if (wantMember) {
      values = {
        name: name,
        phone: location.state ? location.state.phone : "",
        email: email,
        birthDate: birthDate,
        gender: gender,
        masterID: ls.get("sfPartner").id_master,
        firstPartner: ls.get("sfPartner").id
      }
      
      const userDetails = {
        name: name,
        phone: location.state ? location.state.phone : "",
        email: email,
        TglLahir: birthDate,
        Gender: gender
      }

      userLogin(userDetails);

    } else {
      values = {
        name: name,
        phone: location.state ? location.state.phone : "",
        firstPartner: ls.get("sfPartner").id
      }

      const userDetails = {
        name: name,
        phone: location.state ? location.state.phone : "",
        email: email,
        TglLahir: birthDate,
        Gender: gender
      }
      userLogin(userDetails);
    }

    const errors = {}
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

    if (wantMember) {
      if (!values.name) {
        errors.name = 'Name tidak boleh kosong'
      }

      if (!values.email) {
        errors.email = 'Email tidak boleh kosong'
      } else if (!regEx.test(values.email)) {
        errors.email = 'email tidak valid'
      }

      if (!values.birthDate) {
        errors.birthDate = 'Tanggal lahir tidak boleh kosong'
      }

      if (!values.gender) {
        errors.gender = 'Gender tidak boleh kosong'
      }
    } else {
      if (!values.name) {
        errors.name = 'Name tidak boleh kosong'
      }
    }

    setFormErrors(errors)

    if (Object.keys(errors).length === 0) {
      registerUser(values)
    }

  }

  return (
    <DefaultContainer >
      <Toast isOpen={isOpen} message={msg} status={status} />
      <Grid style={{ margin: 15, textAlign: "left" }}>
        <DefaultHeader title="Lengkapi Profil Kamu" />
        <Grid container style={{ marginTop: 15 }}>
          <Grid item xs={12}>
            <DefaultTextField
              autoComplete={"name"}
              label="Nama"
              fullWidth
              autoFocus
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              error={formErrors.name ? true : false}
              helperText={formErrors.name}
            />
          </Grid>
        </Grid>
        {/* <Grid container style={{ marginTop: 15 }}>
          <Grid item xs={1}>
            <Checkbox
              style={{ margin: 0, padding: 0 }}
              value={wantMember}
              onClick={(e) => {
                setWantMember(!wantMember);
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography>
              Daftarkan saya jadi member di {`${ls.get("sfPartner").name}`}
            </Typography>
          </Grid>
        </Grid>
        {wantMember ? (
          <Grid container item xs={12} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Typography>Tanggal Lahir</Typography>
            </Grid>
            <Grid item xs={12}>
              <DefaultTextField
                type={"date"}
                fullWidth
                required
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                error={formErrors.birthDate ? true : false}
                helperText={formErrors.birthDate}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 15 }}>
              <DefaultTextField
                type={"email"}
                fullWidth
                label={"Email"}
                required
                value={email}
                placeholder={"Masukkan email"}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                error={formErrors.email ? true : false}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 15 }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Gender
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Wanita"
                  value={gender}
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="Wanita"
                    control={<Radio />}
                    label="Perempuan"
                  />
                  <FormControlLabel
                    value="Pria"
                    control={<Radio />}
                    label="Laki-laki"
                  />
                </RadioGroup>
                <p style={{ color: '#f44336', fontSize: 13, margin: 0, paddingLeft: 15 }}>{formErrors.gender && formErrors.gender}</p>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )} */}
        <Grid item xs={12}>
          <DefaultButton
            title="daftar"
            onClick={() => formValidation()}
          />
        </Grid>
      </Grid>
    </DefaultContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (userDetail) => {
      dispatch(userLogin(userDetail));
    },
  };
};

CompleteProfileScreen.propTypes = {
  userLogin: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfileScreen);

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultContainer from "../components/DefaultContainer";
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SkeletonLoader from "../components/SkeletonLoader";
import MoneyFormat from "../components/MoneyFormat";
import { getVariantByID } from "../services/Products";
import { Checkbox } from "@material-ui/core";
import DefaultTextField from "../components/DefaultTextField";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { connect } from "react-redux";
import {
  decreaseQuantity,
  deleteFromCart,
  editItemCart,
  increaseQuantity,
} from "../config/redux/actions/cartActions";
import { NumericFormat } from "react-number-format";
import ls from "local-storage";
import Toast from "../components/Snackbar";
import DeleteIcon from "@mui/icons-material/Delete";
import Confirmation from "../components/Confirmation";

const ItemDetails = ({ cartData, editItemCart, deleteItemCart }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [singlePrice, setSinglePrice] = useState(0);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [variants, setVariants] = useState([]);
  const [reqVariants, setReqVariants] = useState(0);
  const [reqVariantsCount, setReqVariantsCount] = useState(0);
  const [notes, setNotes] = useState("");
  const [qty, setQty] = useState(1);
  const [selectedIdVars, setSelectedIdVars] = useState([]);
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [msgDialog, setMsgDialog] = useState("");
  const [titleDialog, setTitleDialog] = useState("");
  const [isOnlyOk, setIsOnlyOk] = useState(false);
  const [isOpeningHour, setIsOpeningHour] = useState(false);
  const [msgToast, setMsgToast] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const seconds = new Date().getSeconds();

  const timeNow = `${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }:${seconds > 9 ? seconds : "0" + seconds}`;

  const imageLoaded = () => {
    setLoading(false);
  };

  const style = {
    marginLeft: 15,
    top: 15,
    right: "auto",
    position: "sticky",
    color: "black",
    backgroundColor: "#fff",
    zIndex: 10,
    borderRadius: "50%",
  };

  async function fetchVariants(productID) {
    await getVariantByID(productID).then((response) => {
      const dataVariants = response["variants"];
      setVariants(dataVariants ?? []);

      let i = reqVariants;

      if (dataVariants.length > 0) {
        dataVariants.forEach((variant) => {
          if (
            variant.variant_group_type === "0" ||
            variant.variant_group_type === 0
          ) {
            i += 1;
          }
        });
      }

      setReqVariants(i);
    });
  }

  const handleChange = (e, idx, idGroup, idVar) => {
    let list = [...selectedVariants];
    const selectedGroupVariant = variants[idx];
    let selectedVariant;

    if (e.target.value) {
      selectedVariant = variants[idx]["variant"].filter(
        (item) => item.id === e.target.value
      )[0];
    } else {
      selectedVariant = variants[idx]["variant"].filter(
        (item) => item.id === idVar
      )[0];
    }

    let variant;
    let totalPriceVariant = 0;
    let selectedOptional = list.filter(
      (item) => item.id_variant === idGroup && item.type == 1
    )[0];

    if (selectedOptional) {
      // cek juga apakah selected variant sudah ada didalam selectedOptional
      let isSelectedVarExist = selectedOptional.data_variant.filter(
        (item) => item.id === selectedVariant.id
      )[0];
      let currVariantExist;

      if (isSelectedVarExist) {
        currVariantExist = selectedOptional.data_variant.filter(
          (item) => item.id !== selectedVariant.id
        );

        // ini kalau currVariantExist.length nya = 0
        if (currVariantExist.length < 1) {
          let currVariant = list.filter((item) => item.id_variant !== idGroup);
          list = [...currVariant];
        } else {
          selectedOptional.data_variant = currVariantExist;

          variant = {
            name: selectedGroupVariant.variant_group_name,
            id_variant: selectedGroupVariant.variant_group_id,
            type: selectedGroupVariant.variant_group_type,
            data_variant: selectedOptional.data_variant,
          };
        }
      } else {
        // ini kalau currVariantExist.length nya lebih dari 0
        variant = {
          name: selectedGroupVariant.variant_group_name,
          id_variant: selectedGroupVariant.variant_group_id,
          type: selectedGroupVariant.variant_group_type,
          data_variant: [
            ...selectedOptional.data_variant,
            {
              id: selectedVariant.id,
              name: selectedVariant.name,
              stock: selectedVariant.stock,
              qty: qty,
              price: selectedVariant.price,
            },
          ],
        };
      }
    } else {
      variant = {
        name: selectedGroupVariant.variant_group_name,
        id_variant: selectedGroupVariant.variant_group_id,
        type: selectedGroupVariant.variant_group_type,
        data_variant: [
          {
            id: selectedVariant.id,
            name: selectedVariant.name,
            stock: selectedVariant.stock,
            qty: qty,
            price: selectedVariant.price,
          },
        ],
      };
    }

    if (list.length > 0) {
      list = list.filter((item) => item.id_variant !== idGroup);
    }

    let newVariantsList;
    if (variant) {
      newVariantsList = [...list, variant];
    } else {
      newVariantsList = [...list];
    }

    let reqVarCount = 0;
    if (newVariantsList.length > 0) {
      newVariantsList.forEach((val) => {
        if (val.type === "0" || val.type === 0) {
          reqVarCount += 1;
        }

        val.data_variant.forEach((item) => {
          totalPriceVariant += parseInt(item.price) * qty;
        });
      });
    }

    let itemPrice =
      parseInt(productDetails.data.harga) + totalPriceVariant / qty;

    setSinglePrice(itemPrice);
    setTotalPrice(itemPrice * qty);
    setReqVariantsCount(reqVarCount);
    setSelectedVariants(newVariantsList);
  };

  function handleAddQty() {
    if (qty < Number(productDetails.data.stock)) {
      setQty(qty + 1);
      setTotalPrice(singlePrice * (qty + 1));

      // update qty variant yang terpilih juga jika ada variant nya
      let list = selectedVariants;
      if (list.length > 0) {
        list.forEach((item) => {
          item.data_variant.forEach((val) => {
            val.qty = qty + 1;
          });
        });
      }

      setSelectedVariants(list);
    }
  }

  function handleReduceQty() {
    if (qty > 1) {
      setQty(qty - 1);
      setTotalPrice(singlePrice * (qty - 1));

      // update qty variant yang terpilih juga jika ada variant nya
      let list = selectedVariants;
      if (list.length > 0) {
        list.forEach((item) => {
          item.data_variant.forEach((val) => {
            val.qty = qty - 1;
          });
        });
      }

      setSelectedVariants(list);
    }
  }

  function handleSaveEdit() {
    setButtonClicked(true);
    let item = {
      ...productDetails,
      notes: notes,
      qty: qty,
      price: singlePrice,
      totalPrice: totalPrice,
      variant: selectedVariants,
      quantity: qty,
    };

    let msgValidate = [];

    item.variant.forEach((data) => {
      data.data_variant.forEach((val) => {
        if (parseInt(val.stock) < item.qty) {
          msgValidate.push(`varian ${val.name}`);
        }
      });
    });

    if (msgValidate.length > 0) {
      msgValidate = msgValidate.toString().replaceAll(",", ", ");
      setMsgDialog(`Stock ${msgValidate} tidak mencukupi.`);
      setTitleDialog("Peringatan");
      setIsOnlyOk(true);
      handleClickOpen();
    } else {
      editItemCart(item);

      setMsg("Berhasil Simpan");
      setIsOpen(true);
      setStatus(1);

      setTimeout(() => {
        back();
      }, 1000);
    }
  }

  function handleDeleteItem() {
    setMsgDialog(
      "Anda yakin akan ingin menghapus item ini? Item tidak dapat dikembalikan setelah terhapus"
    );
    setTitleDialog("Hapus Item");
    handleClickOpen();
  }

  useEffect(() => {
    if (location.state) {
      let list = location.state.details.variant;
      let reqVarCount = 0;
      let listedVariants = [];

      setProductDetails(location.state.details);
      setTotalPrice(location.state.details.totalPrice);
      setSinglePrice(location.state.details.price);
      setSelectedVariants(location.state.details.variant);
      fetchVariants(location.state.details.data.id);
      setQty(location.state.details.qty);
      setNotes(location.state.details.notes);

      if (list.length > 0) {
        list.forEach((val) => {
          if (val.type === "0" || val.type === 0) {
            reqVarCount += 1;
          }

          val.data_variant.forEach((item) => {
            listedVariants.push(item.id);
          });
        });
      }

      setReqVariantsCount(reqVarCount);
      setSelectedIdVars(listedVariants);
    }

    if (
      ls.get("sfOpenHour") &&
      ls.get("sfOpenHour") < timeNow &&
      timeNow > ls.get("sfCloseHour")
    ) {
      setIsOpeningHour(false);
      setMsgToast("Mohon maaf cafe sudah tutup, silahkan coba kembali besok");
      return;
    }

    if (ls.get("sfOpenHour") && ls.get("sfOpenHour") > timeNow) {
      setIsOpeningHour(false);
      setMsgToast("Mohon maaf cafe belum buka");
      return;
    }

    if (ls.get("sfShift") === "1") {
      if (
        ls.get("sfOpenHour") &&
        ls.get("sfOpenHour") < timeNow &&
        timeNow < ls.get("sfCloseHour")
      ) {
        setIsOpeningHour(true);
      }
    } else {
      if (
        ls.get("sfOpenHour") &&
        ls.get("sfOpenHour") < timeNow &&
        timeNow > ls.get("sfLastOrder") &&
        timeNow < ls.get("sfCloseHour")
      ) {
        setIsOpeningHour(false);
        setMsgToast(
          "Mohon maaf waktu pemesanan anda melebihi waktu last order, silahkan coba kembali besok"
        );
        return;
      } else {
        setMsgToast("Resto belum memulai shift. Mohon hubungi kasir");
        return;
      }
    }

    if (msgToast) {
      setMsg(msgToast);
      setIsOpen(true);
      setStatus(0);

      setTimeout(() => {
        setIsOpen(false);
      }, 100);
    }
  }, [location.state, msgToast]);

  function back() {
    navigate(-1);
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsOnlyOk(false);
  };

  const handleClickOk = () => {
    if (isOnlyOk) {
      setIsOnlyOk(false);
    } else {
      setMsg("Berhasil Hapus");
      setIsOpen(true);
      setStatus(1);

      deleteItemCart(productDetails);

      setTimeout(() => {
        back();
      }, 1000);
    }
    setOpen(false);
  };

  return (
    <DefaultContainer bgColor={"#fafafa"} loading={loading}>
      <Toast isOpen={isOpen} message={msg} status={status} />
      <Confirmation
        open={open}
        onlyOk={isOnlyOk}
        onOk={handleClickOk}
        onClose={handleClose}
        title={titleDialog}
        confirmMsg={msgDialog}
      />
      <Box style={{ backgroundColor: grey[50], position: "relative" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: 15,
            zIndex: 10,
          }}
        >
          <Fab
            aria-label="add"
            style={style}
            size={"small"}
            onClick={(e) => {
              back();
            }}
          >
            <ArrowBackOutlinedIcon />
          </Fab>
          <Fab
            aria-label="add"
            style={{
              marginRight: 15,
              top: 15,
              position: "sticky",
              color: "black",
              backgroundColor: "#fff",
              borderRadius: "50%",
            }}
            size={"small"}
            onClick={(e) => {
              handleDeleteItem();
            }}
          >
            <DeleteIcon />
          </Fab>
        </div>
        {productDetails ? (
          <div
            style={{
              marginTop: "-40px",
            }}
          >
            <div style={{ display: loading ? "block" : "none" }}>
              <SkeletonLoader type="productImage" />
            </div>
            <div
              style={{
                display: loading ? "none" : "block",
                width: "100%",
                height: 400,
                overflow: "hidden",
              }}
            >
              <img
                key={productDetails.data.img_data}
                src={productDetails.data.img_data}
                alt="product"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onLoad={() => setTimeout(() => setLoading(false), 250)}
                onError={(e) => {
                  e.target.src =
                    "https://www.rabrotech.com/upload/default/image-not-found.png";
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="test"
            style={{
              width: "100%",
              height: 400,
              overflow: "hidden",
              marginTop: "-40px",
            }}
          >
            <img
              src="/assets/img/image-not-found.png"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt={"Product"}
            />
          </div>
        )}
        {/* </Box> */}
        <Box style={{ backgroundColor: grey[50] }}>
          <Box
            style={{
              marginLeft: 15,
              marginRight: 15,
              marginTop: 15,
              paddingBottom: "60px",
            }}
          >
            <Grid item>
              {/* Nama Item */}
              <Typography variant="h5" style={{ fontWeight: "500" }}>
                {productDetails ? productDetails.data.nama : ""}
              </Typography>
            </Grid>

            {/* Deskripsi Item */}
            <Grid item>
              <Typography>
                {productDetails ? productDetails.data.Deskripsi : ""}
              </Typography>
            </Grid>

            {/* Harga Item */}
            <Grid item>
              <Typography variant="h5">
                {" "}
                <MoneyFormat value={singlePrice} />
              </Typography>
            </Grid>

            {/* Variant Item */}
            <Grid container>
              {variants !== null ? (
                variants &&
                variants.map((k, i) => {
                  return (
                    <Grid
                      key={variants[i].variant_group_id}
                      style={{ marginTop: 15, width: "100%" }}
                    >
                      <FormControl style={{ width: "100%" }}>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{
                            overflowWrap: "break-word",
                            maxWidth:
                              sessionStorage.getItem("comparison") < 1
                                ? sessionStorage.getItem("width") * 0.9
                                : 420,
                          }}
                        >
                          {variants[i].variant_group_name +
                            (variants[i].variant_group_type === "0"
                              ? " (Wajib pilih 1)"
                              : " (Opsional)")}
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="radio-buttons-group"
                        >
                          {variants[i].variant &&
                            variants[i].variant.map((l, j) => {
                              return (
                                <Grid
                                  style={{ marginTop: 10 }}
                                  container
                                  item
                                  key={variants[i].variant[j].id}
                                  alignItems="center"
                                >
                                  <Grid item xs={9}>
                                    <FormControlLabel
                                      // style={{ marginRight: 150 }}
                                      value={variants[i].variant[j].id}
                                      label={
                                        <Typography
                                          style={{
                                            overflowWrap: "break-word",
                                            maxWidth:
                                              sessionStorage.getItem(
                                                "comparison"
                                              ) < 1
                                                ? sessionStorage.getItem(
                                                    "width"
                                                  ) * 0.9
                                                : 420,
                                          }}
                                        >
                                          {variants[i].variant[j].name}
                                        </Typography>
                                      }
                                      control={
                                        variants[i].variant_group_type ===
                                        "0" ? (
                                          <Radio
                                            disabled={
                                              variants[i].variant[j].stock <=
                                              "0"
                                                ? true
                                                : false
                                            }
                                            value={
                                              selectedIdVars.includes(
                                                `${variants[i].variant[j]["id"]}`
                                              )
                                                ? ""
                                                : variants[i].variant[j]["id"]
                                            }
                                            onChange={(e) => {
                                              const idVar =
                                                variants[i].variant[j]["id"];
                                              handleChange(
                                                e,
                                                i,
                                                k.variant_group_id,
                                                idVar
                                              );
                                            }}
                                          />
                                        ) : (
                                          <Checkbox
                                            disabled={
                                              variants[i].variant[j].stock <=
                                              "0"
                                                ? true
                                                : false
                                            }
                                            defaultChecked={selectedIdVars.includes(
                                              `${variants[i].variant[j]["id"]}`
                                            )}
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                i,
                                                k.variant_group_id,
                                                k.variant_group_type
                                              )
                                            }
                                          />
                                        )
                                      }
                                    />
                                    {variants[i].variant[j].stock <= "0" && (
                                      <span style={{ color: "red" }}>
                                        stok habis
                                      </span>
                                    )}
                                  </Grid>
                                  <Grid item xs={3} textAlign="right">
                                    {variants[i].variant[j].price === "0" ? (
                                      <div></div>
                                    ) : (
                                      <MoneyFormat
                                        value={variants[i].variant[j].price}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </RadioGroup>{" "}
                      </FormControl>
                    </Grid>
                  );
                })
              ) : (
                <div></div>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: 15, marginBottom: 30 }}>
              <Box component="form" sx={{}} noValidate autoComplete="off">
                <DefaultTextField
                  label="Catatan"
                  id="outlined-basic"
                  variant="outlined"
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
        <Grid
          item
          container
          style={{
            bottom: 0,
            paddingRight: 15,
            paddingTop: 15,
            paddingBottom: 15,
            // left: 15,
            // top: "0",
            position: "fixed",
            backgroundColor: "white",
            zIndex: 2,
            maxWidth: 480,
          }}
        >
          <Grid item container xs={5} alignItems={"center"}>
            <Button
              onClick={(e) => {
                handleReduceQty();
              }}
            >
              <RemoveCircleOutlineRoundedIcon fontSize="large" />
            </Button>
            <Typography>{qty}</Typography>
            <Button
              onClick={(e) => {
                handleAddQty();
              }}
            >
              <AddCircleOutlineRoundedIcon fontSize="large" />
            </Button>
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              style={{
                fontWeight: "bold",
                color: "white",
                borderRadius: 10,
                background:
                  !buttonClicked &&
                  reqVariantsCount === reqVariants &&
                  isOpeningHour === true
                    ? "#30412F"
                    : "gray",
              }}
              onClick={(e) => {
                handleSaveEdit();
              }}
              disabled={
                buttonClicked ||
                (isOpeningHour
                  ? reqVariants !== reqVariantsCount
                    ? true
                    : false
                  : true)
              }
            >
              <Typography style={{ fontWeight: "bold", padding: 5 }}>
                Simpan {"- "}
                <NumericFormat
                  value={totalPrice}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DefaultContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData.filter(
      (data) => data.partnerID === ls.get("partnerID")
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    decreaseQuantity: (item, addToast) => {
      dispatch(decreaseQuantity(item, addToast));
    },
    increaseQuantity: (item, addToast) => {
      dispatch(increaseQuantity(item, addToast));
    },
    editItemCart: (item) => {
      dispatch(editItemCart(item));
    },
    deleteItemCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);

import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
} from "@mui/material";
import ls from "local-storage";
import DefaultContainer from "../components/DefaultContainer";
import DefaultHeader from '../components/DefaultHeader';
import { getByPartnerID } from "../services/Review";
import ReviewItem from './ReviewItem';
import CircularProgress from '@mui/material/CircularProgress';
import StarRateIcon from '@mui/icons-material/StarRate';
import LinearProgress from '@mui/material/LinearProgress';

const ReviewList = () => {

    const [reviews, setReviews] = useState("");
    const [averageReview, setAverageReview] = useState(0);
    const [reviewCount, setReviewCount] = useState(0);
    const [starIcons, setStarIcons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groupedByRate, setGroupedByRate] = useState([
        {
            rating: "1",
            count: 0,
            percent: 0
        },
        {
            rating: "2",
            count: 0,
            percent: 0
        },
        {
            rating: "3",
            count: 0,
            percent: 0
        },
        {
            rating: "4",
            count: 0,
            percent: 0
        },
        {
            rating: "5",
            count: 0,
            percent: 0
        }
    ]);

    const fetchReviews = async () => {
        const response = await getByPartnerID(ls.get('partnerID'))

        if (response.success === 1) {
            setReviews(response.reviews)

            let icons = [];
            if (response.average) {
                for (let i = 0; i < parseFloat(response.average); i++) {
                    icons.push(
                        <StarRateIcon
                            key={i}
                            style={{
                                color: "#FFDF00",
                                fontSize: 13
                            }}
                        />
                    );
                }

                setStarIcons(icons)
            }

            let items = response.grouppedReviews
            const itemsCount = parseInt(response.count)
            let list = groupedByRate;

            list.forEach(item => {
                const filterItem = items.filter(x => x.rating === item.rating)[0]

                if (filterItem) {
                    const count = filterItem.count
                    item.count = count
                    item.percent = Math.round(count / itemsCount * 100)
                }

                let iconsStar = [];
                for (let i = 1; i <= 5; i++) {
                    iconsStar.push(
                        <StarRateIcon
                            key={i}
                            style={{
                                color: i <= parseInt(item.rating) ? "#FFDF00" : "#bbbdbc",
                                fontSize: 13
                            }}
                        />
                    );
                }
                item.icons = iconsStar
            })

            list = list.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));

            setGroupedByRate([...list])
            setReviewCount(parseInt(response.count))
            setAverageReview(parseFloat((response.average).toFixed(1)))

            setLoading(false)
        } else {
            setReviews([])
            setLoading(false)
        }
    }

    const [width, setWindowWidth] = useState(0);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    useEffect(() => {
        fetchReviews()
        updateDimensions();

        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [])

    return (
        <DefaultContainer
            bgColor={"#fff"}
            loading={loading}
        >
            <Grid>
                <Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            background: "#fff",
                            padding: 15,
                            textAlign: "center",
                            position: "sticky",
                            top: 0,
                            zIndex: 2
                        }}
                    >
                        <DefaultHeader
                            title={"Ulasan"}
                        />
                    </Grid>
                    {
                        reviews && reviews.length > 0 &&
                        <Grid
                            container
                            style={{
                                paddingLeft: 15,
                                paddingRight: 15,
                                borderBottom: '1px solid #6c757d',
                                marginBottom: 15,
                                position: "sticky",
                                top: 60,
                                zIndex: 3,
                                background: '#fff',
                                display: 'flex',
                                alignItems: 'bottom',

                            }}
                        >
                            <Grid
                                item
                                xs={3}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: 10,
                                    paddingRight: 10,
                                }}
                            >
                                <Typography>
                                    <span
                                        style={{
                                            fontSize: 24,
                                            fontWeight: 500
                                        }}
                                    >
                                        {averageReview}
                                    </span>
                                    <span>
                                        / 5
                                    </span>
                                </Typography>
                                <div>
                                    {starIcons}
                                </div>
                                <Typography
                                    style={{
                                        marginTop: 5
                                    }}
                                >
                                    {reviewCount} ulasan
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={9}
                                style={{
                                    paddingBottom: 10,
                                    paddingLeft: 15,
                                    borderLeft: '1px solid #6c757d'
                                }}
                            >
                                {groupedByRate.length > 0 &&
                                    groupedByRate.map(rate => {
                                        return (
                                            <Grid
                                                container
                                                key={rate.rating}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={width < 398 ? 4 : 3}
                                                >
                                                    {rate.icons}

                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={width < 398 ? 6 : 7}
                                                    style={{
                                                        paddingLeft: width > 398 ? 5 : ""
                                                    }}
                                                >
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={rate.percent}
                                                        sx={{
                                                            backgroundColor: `#bbbdbc`,
                                                            "& .MuiLinearProgress-bar": {
                                                                backgroundColor: `#FFDF00`
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {rate.count}
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        loading ?
                            <Grid
                                style={{
                                    height: '100%',
                                    position: 'fixed',
                                    left: '45%',
                                    top: '47%'
                                }}
                            >
                                <CircularProgress />
                            </Grid>
                            :
                            <Grid
                                style={{
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    paddingBottom: 15,
                                    height: !reviews || reviews.length === 0 ? '100vh' : ""

                                }}
                            >
                                {reviews && reviews.length > 0
                                    ?
                                    reviews.map(review => {
                                        return (
                                            <ReviewItem
                                                key={review.id}
                                                data={review}
                                            />
                                        )
                                    })
                                    :
                                    <div
                                        style={{
                                            height: '100%',
                                            position: 'fixed',
                                            left: width < 480 ? '32%' : "40%",
                                            top: '47%'
                                        }}
                                    >
                                        <Typography>
                                            Belum ada penilaian
                                        </Typography>
                                    </div>
                                }
                            </Grid>
                    }
                </Grid>
            </Grid>
        </DefaultContainer>
    )
}

export default ReviewList
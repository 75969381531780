export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const REMOVE_CART = "REMOVE_CART";
export const EDIT_ITEM_CART = "EDIT_ITEM_CART";

//add to cart
export const addToCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        selectedProductColor: null,
        selectedProductSize: null,
      },
    });
  };
};
// save edit item cart
export const editItemCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_ITEM_CART,
      payload: {
        ...item,
        selectedProductColor: null,
        selectedProductSize: null,
      },
    });
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//increase from cart
export const increaseQuantity = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Item Increased From Cart", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    dispatch({ type: INCREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = (addToast) => {
  return (dispatch) => {
    // if (addToast) {
    //   addToast("Removed All From Cart", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // }
    dispatch({ type: REMOVE_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
};

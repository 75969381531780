import React from "react";
import DefaultContainer from "./DefaultContainer";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router";
import { PriorityHigh, ShoppingBagRounded } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import ls from "local-storage";

export function OverlayReview() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/order");
  };

  return (
    <DefaultContainer bgColor={"primary.main"} noBanner={true}>
      <div
        style={{
          height: "100vh",
          backgroundColor: "#30412F",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: "70%",
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                fontWeight: 500,
                marginTop: 10,
                textAlign: "center",
                marginBottom: "2rem",
              }}
            >
              Terima Kasih Telah Memberi Ulasan
            </Typography>
            {ls.get("sfIsReview") === "1" ? (
              <div></div>
            ) : (
              <Button
                fullWidth
                variant="outlined"
                color="white"
                onClick={() => handleNavigate()}
              >
                <Typography style={{ padding: 3 }}>Belanja Kembali</Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    </DefaultContainer>
  );
}

export default function Overlay({
  message,
  refreshButton,
  routeToGo,
  routeLabel,
  warning,
  check,
  loading,
}) {
  const navigate = useNavigate();

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleNavigate = () => {
    navigate(routeToGo);
  };

  return (
    <DefaultContainer bgColor={"primary.main"} noBanner={true}>
      <div
        style={{
          height: "100vh",
          backgroundColor: "#30412F",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: "70%",
            }}
          >
            {warning && (
              <PriorityHigh
                style={{
                  fontSize: 90,
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            )}

            {check && (
              <DoneIcon
                style={{
                  fontSize: 90,
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            )}

            {loading && (
              <ShoppingBagRounded
                style={{
                  fontSize: 90,
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            )}

            <Typography
              style={{
                fontSize: 20,
                fontWeight: 500,
                marginTop: 10,
                textAlign: "center",
                marginBottom: "2rem",
              }}
            >
              {message}
            </Typography>

            {refreshButton ? (
              <Button
                fullWidth
                variant="outlined"
                color="white"
                onClick={() => handleRefresh()}
              >
                <Typography style={{ padding: 5 }}>Refresh</Typography>
              </Button>
            ) : routeToGo ? (
              <Button
                fullWidth
                variant="outlined"
                color="white"
                onClick={() => handleNavigate()}
              >
                <Typography style={{ padding: 3 }}>{routeLabel}</Typography>
              </Button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </DefaultContainer>
  );
}

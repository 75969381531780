import React, { useState, useEffect } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultContainer from "../components/DefaultContainer";
import DefaultHeader from "../components/DefaultHeader";
import MoneyFormat from "../components/MoneyFormat";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { getAll } from "../services/CustomPaymentMethods";
import { create, getDetails, reCreate } from "../services/Transactions";
// import Confirmation from "../components/Confirmation";
import Toast from "../components/Snackbar";
import ls from "local-storage";
import { deleteAllFromCart } from "../config/redux/actions/cartActions";
import { connect } from "react-redux";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SkeletonPayment from "../components/SkeletonPayment";

const Payment = ({ deleteAllFromCart, cartData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState({});
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  // const [existingPM, setExistingPM] = useState("");
  const [trxData, setTrxData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [paymentWay, setPaymentWay] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [currentPayment, setCurrentPayment] = useState("");
  const iconStyle = {
    fontSize: 30,
    marginLeft: 10,
  };
  const [paymentMethod, setPaymentMethod] = useState([
    {
      id: "14",
      name: "QRIS-Kadin",
      icon: <QrCodeScannerIcon style={iconStyle} />,
      status: "0",
    },
    {
      id: "1",
      name: "OVO",
      img: "/assets/img/logo/ovo.png",
      status: "0",
    },
    {
      id: "2",
      name: "GOPAY",
      img: "/assets/img/logo/gopay.png",
      status: "0",
    },
    {
      id: "3",
      name: "DANA",
      img: "/assets/img/logo/dana.png",
      status: "0",
    },
    {
      id: "4",
      name: "LINKAJA",
      img: "/assets/img/logo/linkaja.png",
      status: "0",
    },
    {
      id: "10",
      name: "SHOPEEPAY",
      img: "/assets/img/logo/shopeepay.png",
      status: "0",
    },
    {
      id: "5",
      name: "TUNAI",
      icon: <LocalAtmIcon style={iconStyle} />,
      status: "0",
    },
    {
      id: "7",
      name: "CREDIT CARD",
      icon: <CreditCardIcon style={iconStyle} />,
      status: "0",
    },
    {
      id: "8",
      name: "DEBIT CARD",
      icon: <CreditScoreIcon style={iconStyle} />,
      status: "0",
    },
    {
      id: "9",
      name: "QRIS",
      icon: <QrCodeScannerIcon style={iconStyle} />,
      status: "0",
    },
  ]);

  const fetchPaymentMethods = async () => {
    const isCashActive = ls.get("sfPartner").cash_active;
    const isCreditActive = ls.get("sfPartner").cc_active;
    const isDebitActive = ls.get("sfPartner").debit_active;
    const isQrisActive = ls.get("sfPartner").qris_active;

    const response = await getAll();

    if (response.success === 1) {
      const paymentMethods = response.payment_methods;
      const payAtCashier = response.pay_at_cashier;
      let payAtCashierValidation = false;
      if (payAtCashier.length > 0) {
        payAtCashierValidation = payAtCashier.some((item, i) => {
          return item === ls.get("sfPartner").id;
        });
      }

      let list = [];
      if (paymentMethods.length > 0) {
        paymentMethod.forEach((payment) => {
          if (
            payment.id === "1" ||
            payment.id === "2" ||
            payment.id === "3" ||
            payment.id === "4" ||
            payment.id === "10" ||
            payment.id === "14"
          ) {
            const isActive = paymentMethods.filter(
              (item) => item.id === payment.id
            )[0]["status"];
            payment.status = isActive;
          }

          if (payAtCashierValidation) {
            //validation alchemist (partner wants take away but not wanting to use ewallet as payment)
            if (payment.id === "5" && isCashActive === "1") {
              payment.status = "1";
            } else if (payment.id === "7" && isCreditActive === "1") {
              payment.status = "1";
            } else if (payment.id === "8" && isDebitActive === "1") {
              payment.status = "1";
            } else if (payment.id === "9" && isQrisActive === "1") {
              payment.status = "1";
            }
          }

          list.push(payment);
        });
        setPaymentMethod(list);
        setLoading(false);
      }
    }
  };

  const fetchDetails = async (id) => {
    const response = await getDetails({ id: id });
    if (response.success === 1) {
      setDetails(response.details);
      setTotal(response.total);
    }
  };

  useEffect(() => {
    if (
      !ls.get("redux_localstorage_simple") ||
      ls.get("sfToken") === null ||
      ls
        .get("sfToken")
        .includes(
          "dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz"
        ) ||
      ls
        .get("sfToken")
        .includes(
          "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0="
        )
    ) {
      return navigate("/auth", {
        state:
          location.state.totalAfterRounding || location.state.total
            ? { ...location.state, to: "/payment" }
            : { to: "/cart" },
      });
    }

    if (location.state) {
      if (location.state.paymentWay === 0) {
        createTrx("Cashier", location.state);
        setTrxData(location.state);
        setTotal(parseInt(location.state.totalAfterRounding));
        setSubtotal(location.state.subtotal);
      } else {
        if (
          location.state.transactionID &&
          location.state.paymentWay !== "Change Method"
        ) {
          fetchDetails(location.state.transactionID);
        } else {
          console.log(location.state.pmName);
          setCurrentPayment(location.state.pmName);
          setPaymentWay(location.state.paymentWay);
          setTrxData(location.state);
          setTotal(parseInt(location.state.totalAfterRounding));
          setSubtotal(location.state.subtotal);
        }
      }
    } else {
      // supaya tidak bisa akses ketika payment di ketik langsung di address bar
      navigate(-1);
    }

    fetchPaymentMethods();
  }, [location.state]);

  const handleSelectPayment = (id, name, img, icon) => {
    setSelectedPayment({ id, name, img, icon });
    setTrxData({ ...trxData, paymentMethod: id });
  };

  const createTrxEwallet = async () => {
    if (details) {
      const data = {
        transactionID: location.state.transactionID,
        paymentMethod: selectedPayment.id,
      };
      setIsBtnDisabled(true);
      const response = await reCreate(data);

      if (response.success === 1) {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        let urlResponse = "";
        if (response.ewallet_response.actions) {
          if (response.ewallet_response.actions.mobile_deeplink_checkout_url) {
            urlResponse =
              response.ewallet_response.actions.mobile_deeplink_checkout_url;
          } else if (
            response.ewallet_response.actions.mobile_web_checkout_url
          ) {
            urlResponse =
              response.ewallet_response.actions.mobile_web_checkout_url;
          }
        } else {
          navigate(
            `/success?transactionID=${response.transaction_id}&status=0`
          );
        }

        if (urlResponse) {
          deleteAllFromCart();
          // window.open(urlResponse, '_blank')
          // if (selectedPayment.id === "2") {
          navigate(
            `/success?transactionID=${response.transaction_id}&status=0`,
            {
              state: { urlResponse },
            }
          );
          // } else {
          // navigate("/order")
          // }
        }
        setTimeout(() => {
          setIsBtnDisabled(false);
        }, 1000);
      } else {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);
        setIsBtnDisabled(false);
      }
    } else {
      let data = trxData;
      const response = await create(data);
      setIsBtnDisabled(true);

      if (response.success === 1) {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        let urlResponse = "";
        if (response.ewallet_response.actions) {
          if (response.ewallet_response.actions.mobile_deeplink_checkout_url) {
            urlResponse =
              response.ewallet_response.actions.mobile_deeplink_checkout_url;
          } else if (
            response.ewallet_response.actions.mobile_web_checkout_url
          ) {
            urlResponse =
              response.ewallet_response.actions.mobile_web_checkout_url;
          } else if (selectedPayment.id === "2") {
            urlResponse = response.ewallet_response.actions[1].url;
          }
        } else {
          deleteAllFromCart();
          navigate(
            `/success?transactionID=${response.transaction_id}&status=0`,
            {
              replace: true,
            }
          );
        }

        if (urlResponse) {
          deleteAllFromCart();
          // window.open(urlResponse, '_blank')
          // if (selectedPayment.id === "2") {
          navigate(
            `/success?transactionID=${response.transaction_id}&status=0`,
            {
              state: { urlResponse },
            }
          );
          // } else {
          //     navigate("/order")
          // }
        }

        setTimeout(() => {
          setIsBtnDisabled(false);
        }, 1000);
      } else {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);
        setIsBtnDisabled(false);
      }
    }
  };

  const changePaymentMethod = async () => {
    const data = {
      transactionID: location.state.transactionID,
      paymentMethod: selectedPayment.id,
    };
    setIsBtnDisabled(true);
    const response = await reCreate(data);

    if (response.success === 1) {
      setIsOpen(true);
      setMsg(response.msg);
      setStatus(response.success);

      // if (response.success === 1) {
      //     setIsOpen(true);
      //     setMsg(response.msg);
      //     setStatus(response.success);

      //     setTimeout(() => {
      //         deleteAllFromCart();
      //         setIsBtnDisabled(false);
      if (selectedPayment.id === "14") {
        return navigate(`/qris-ur?transactionID=${response.newID}`, {
          replace: true,
        });
      }
      // }, 1000);

      let urlResponse = "";
      if (response.ewallet_response.actions) {
        if (response.ewallet_response.actions.mobile_deeplink_checkout_url) {
          urlResponse =
            response.ewallet_response.actions.mobile_deeplink_checkout_url;
        } else if (response.ewallet_response.actions.mobile_web_checkout_url) {
          urlResponse =
            response.ewallet_response.actions.mobile_web_checkout_url;
        } else if (selectedPayment.id === "2") {
          urlResponse = response.ewallet_response.actions[1].url;
        }
      } else {
        navigate(`/success?transactionID=${response.newID}&status=0`);
      }

      if (urlResponse) {
        deleteAllFromCart();
        navigate(`/success?transactionID=${response.newID}&status=0`, {
          state: { urlResponse },
        });
      }
      setTimeout(() => {
        setIsBtnDisabled(false);
      }, 1000);
    } else {
      setIsOpen(true);
      setMsg(response.msg);
      setStatus(response.success);
      setIsBtnDisabled(false);
    }
  };

  const createTrx = async (paymentWay, dataTrx) => {
    if (details) {
      const data = {
        transactionID: location.state.transactionID,
        paymentMethod: paymentWay === "Cashier" ? "11" : selectedPayment.id,
      };

      setIsBtnDisabled(true);
      const response = await reCreate(data);

      if (response.success === 1) {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        setTimeout(() => {
          deleteAllFromCart();
          setIsBtnDisabled(false);
          if (selectedPayment.id === "14") {
            navigate(`/qris-ur?transactionID=${response.newID}`, {
              replace: true,
            });
          } else {
            navigate(`/success?transactionID=${response.newID}&status=0`, {
              replace: true,
            });
          }
        }, 1000);
      } else {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        setTimeout(() => {
          setIsBtnDisabled(false);
          setIsOpen(false);
        }, 100);
      }
    } else {
      let data = dataTrx ?? trxData;
      data.paymentMethod = paymentWay === "Cashier" ? "11" : selectedPayment.id;

      setIsBtnDisabled(true);

      const response = await create(data);

      if (response.success === 1) {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        setTimeout(() => {
          deleteAllFromCart();
          setIsBtnDisabled(false);
          if (selectedPayment.id === "14") {
            navigate(`/qris-ur?transactionID=${response.transaction_id}`, {
              replace: true,
              state: { data },
            });
          } else if (
            data.paymentMethod === "11" &&
            (ls.get("sfPartner").open_bill === 1 ||
              ls.get("sfPartner").open_bill === "1")
          ) {
            navigate("/order-received", {
              state: {
                id: response.transaction_id,
                membership: response.is_membership,
                to: "/order",
                phone: ls.get("redux_localstorage_simple").userData[0].phone,
                ...location.state,
              },
            });

            // if(response.is_membership === false){
            //     navigate('/membership', {state: {to: "/order", phone:ls.get("redux_localstorage_simple").userData[0].phone}})
            // } else {
            //     navigate('/order', {state: location.state})
            // }
          } else {
            navigate(
              `/success?transactionID=${response.transaction_id}&status=0`,
              {
                replace: true,
              }
            );
          }
        }, 1000);
      } else {
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        setTimeout(() => {
          setIsBtnDisabled(false);
          setIsOpen(false);
        }, 100);
      }
    }
  };

  const handlePay = () => {
    setIsBtnDisabled(true);

    let validatePayment;
    const data = trxData.program_payment_method;

    if (data) {
      validatePayment = data.find(
        (method) => method.id === trxData.paymentMethod
      );
    } else {
      if (location.state.paymentWay === "Change Method") {
        changePaymentMethod();
      } else if (
        selectedPayment.id === "5" ||
        selectedPayment.id === "7" ||
        selectedPayment.id === "8" ||
        selectedPayment.id === "9" ||
        selectedPayment.id === "14"
      ) {
        createTrx();
      } else {
        createTrxEwallet();
      }

      return;
    }

    if (validatePayment || trxData.program_discount === 0) {
      if (
        selectedPayment.id === "5" ||
        selectedPayment.id === "7" ||
        selectedPayment.id === "8" ||
        selectedPayment.id === "9" ||
        selectedPayment.id === "14"
      ) {
        createTrx();
      } else {
        createTrxEwallet();
      }
    } else {
      setIsOpen(true);
      setMsg(
        `Metode pembayaran yang kamu pilih tidak bisa digunakan pada "${trxData.program_title}"`
      );
      setStatus(0);

      setTimeout(() => {
        setIsBtnDisabled(false);
        setIsOpen(false);
      }, 100);
    }
  };

  return (
    <DefaultContainer loading={loading}>
      <Toast isOpen={isOpen} message={msg} status={status} />
      {loading ? (
        <SkeletonPayment />
      ) : (
        <>
          <Box>
            <Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    background: "#fff",
                    padding: 15,
                    textAlign: "center",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                  }}
                >
                  <DefaultHeader
                    title={"Pilih Metode Pembayaran"}
                    specialActions={"payment"}
                    url={
                      location.state.paymentWay !== "Change Method"
                        ? "/cart"
                        : location.state.transactionID
                        ? `/history-detail?transactionID=${location.state.transactionID}`
                        : "/cart"
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "0px 15px 15px",
                    background: "#fff",
                    position: "sticky",
                    top: 60,
                    zIndex: 2,
                  }}
                >
                  <Typography>Total Pembayaran</Typography>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    <MoneyFormat value={total} />
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    padding: 15,
                    background: "#EFEFEF",
                    position: "sticky",
                    top: 129,
                    zIndex: 2,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    Bayar Langsung
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    background: "#fff",
                    marginBottom: 76,
                  }}
                >
                  {paymentMethod &&
                    paymentMethod.length > 0 &&
                    paymentMethod.map((payment, idx) => {
                      if (payment.status === "1") {
                        return (
                          <div
                            key={idx}
                            style={{
                              borderBottom: "1px solid #EFEFEF",
                              padding: 15,
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              backgroundColor:
                                (payment.id === "1" &&
                                  (total > 2000000 || total < 100)) ||
                                (payment.id === "2" &&
                                  (total > 2000000 || total < 100)) ||
                                (payment.id === "3" &&
                                  (total > 10000000 || total < 100)) ||
                                (payment.id === "4" &&
                                  (total > 10000000 || total < 100)) ||
                                (payment.id === "10" && total < 100) ||
                                (payment.id === "14" &&
                                  (total > 10000000 || total < 1)) ||
                                ((payment.name === currentPayment ||
                                  (payment.name === "QRIS-Kadin" &&
                                    currentPayment === "QRIS Kadin")) &&
                                  paymentWay === "Change Method")
                                  ? "#EFEFEF"
                                  : "white",
                              pointerEvents:
                                (payment.name === currentPayment ||
                                  (payment.name === "QRIS-Kadin" &&
                                    currentPayment === "QRIS Kadin")) &&
                                paymentWay === "Change Method"
                                  ? "none"
                                  : "auto",
                            }}
                            onClick={() => {
                              if (
                                (payment.id === "1" &&
                                  (total > 2000000 || total < 100)) ||
                                (payment.id === "2" &&
                                  (total > 2000000 || total < 100)) ||
                                (payment.id === "3" &&
                                  (total > 10000000 || total < 100)) ||
                                (payment.id === "4" &&
                                  (total > 10000000 || total < 100)) ||
                                (payment.id === "10" && total < 100) ||
                                (payment.id === "14" &&
                                  (total > 10000000 || total < 1))
                              ) {
                                if (
                                  (payment.id === "1" || payment.id === "2") &&
                                  total > 2000000
                                ) {
                                  setStatus(400);
                                  setMsg(
                                    `Pembayaran ${payment.name} tidak bisa lebih dari 2 juta rupiah`
                                  );
                                  setIsOpen(true);
                                } else if (
                                  (payment.id === "3" ||
                                    payment.id === "4" ||
                                    payment.id === "14") &&
                                  total > 10000000
                                ) {
                                  setStatus(400);
                                  setMsg(
                                    `Pembayaran ${payment.name} tidak bisa lebih dari 10 juta rupiah`
                                  );
                                  setIsOpen(true);
                                } else if (
                                  (payment.id === "1" ||
                                    payment.id === "2" ||
                                    payment.id === "3" ||
                                    payment.id === "4" ||
                                    payment.id === "10") &&
                                  total < 100
                                ) {
                                  setStatus(400);
                                  setMsg(
                                    `Pembayaran ${payment.name} tidak bisa lebih kurang dari 100 rupiah`
                                  );
                                  setIsOpen(true);
                                } else if (payment.id === "14" && total < 1) {
                                  setStatus(400);
                                  setMsg(
                                    `Pembayaran ${payment.name} tidak bisa lebih kurang dari 1 rupiah`
                                  );
                                  setIsOpen(true);
                                }

                                setTimeout(() => {
                                  setIsOpen(false);
                                }, 1000);
                              } else {
                                handleSelectPayment(
                                  payment.id,
                                  payment.name,
                                  payment.img,
                                  payment.icon
                                );
                              }
                            }}
                          >
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {payment.img ? (
                                <div
                                  style={{
                                    width: 50,
                                    hieght: 50,
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={payment.img}
                                    style={{
                                      width: "100%",
                                      hieght: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              ) : payment.id === "5" ||
                                payment.id === "7" ||
                                payment.id === "8" ||
                                payment.id === "9" ||
                                payment.id === "14" ? (
                                <>{payment.icon}</>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={5}>
                              <div>
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                  }}
                                >
                                  {payment.name}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "gray",
                                  }}
                                >
                                  {payment.id === "5" ||
                                  payment.id === "7" ||
                                  payment.id === "8" ||
                                  payment.id === "9"
                                    ? "Bayar di kasir"
                                    : "Bayar di HP"}
                                </Typography>
                                {(payment.name === currentPayment ||
                                  (payment.name === "QRIS-Kadin" &&
                                    currentPayment === "QRIS Kadin")) &&
                                paymentWay === "Change Method" ? (
                                  <Typography
                                    style={{ color: "red", fontSize: "1.5vh" }}
                                  >
                                    Metode Bayar Saat Ini
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={5} textAlign={"right"}>
                              <NavigateNextIcon
                                style={{
                                  color: "gray",
                                }}
                              />
                            </Grid>
                          </div>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid
            item
            container
            style={{
              bottom: 0,
              paddingRight: 15,
              paddingTop: 15,
              paddingBottom: 15,
              position: "fixed",
              backgroundColor: "white",
              zIndex: 2,
              maxWidth: 480,
              borderTop: "1.5px solid #EFEFEF",
            }}
          >
            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 15,
              }}
            >
              {Object.keys(selectedPayment).length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                  }}
                >
                  {selectedPayment.img ? (
                    <div
                      style={{
                        width: 50,
                        height: 50,
                        position: "relative",
                      }}
                    >
                      <img
                        alt=""
                        src={selectedPayment.img}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : (
                    <>{selectedPayment.icon}</>
                  )}
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {selectedPayment.name}
                  </Typography>
                </div>
              ) : (
                <div>
                  <Button variant="text" disabled>
                    Pilih metode bayar
                  </Button>
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                style={{
                  fontWeight: "bold",
                  color: "white",
                  borderRadius: 10,
                  background:
                    Object.keys(selectedPayment).length > 0
                      ? isBtnDisabled
                        ? ""
                        : "#30412F"
                      : "",
                }}
                onClick={(e) => {
                  ls.get("sfToken") === null ||
                  ls
                    .get("sfToken")
                    ?.includes(
                      "dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz"
                    ) ||
                  ls
                    .get("sfToken")
                    ?.includes(
                      "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0="
                    )
                    ? navigate("/auth", {
                        replace: true,
                        state: { to: "/payment" },
                      })
                    : handlePay();
                }}
                disabled={
                  Object.keys(selectedPayment).length > 0 ? isBtnDisabled : true
                }
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    padding: 5,
                  }}
                >
                  Bayar
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </DefaultContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData.filter(
      (data) => data.partnerID === ls.get("partnerID")
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  DELETE_FROM_CART,
  REMOVE_CART,
  EDIT_ITEM_CART
} from "../actions/cartActions";
import ls from "local-storage";

const initState = [];

const cartReducer = (state = initState, action) => {
  const { v4: uuidv4 } = require("uuid");
  const cartItems = state,
    product = action.payload;

  let isVariantSame = false;
  let currVariantID = []
  let prodVariantID = []

  if (action.type === ADD_TO_CART) {
    product.variant.forEach(item => {
      prodVariantID.push(item.data_variant[0].id)
    })

    // for non variant products
    if (product.variant.length === 0) {
      const cartItem = cartItems.filter((item) => item.id === product.id && item.variant.length === 0)[0];

      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.qty,
            cartItemId: uuidv4(),
          },
        ];
      } else {
        // jika opsional saja, dia masuknya ke tidak ada varant maka
        // tangani disini
        
        if (product.data.is_variant === "1") {
          if((product.id === cartItem.id) && (product.variant.length === cartItem.variant.length)) {
            let currCartItem

            currCartItem = cartItems.filter(
              (item, i) => item.cartItemId !== cartItem.cartItemId
            );

            return [
              ...currCartItem,
              {
                ...cartItem,
                quantity: cartItem.quantity + product.qty,
                qty: cartItem.qty + product.qty,
                totalPrice: (cartItem.qty + product.qty) * product.price,
                notes: cartItem.notes ? cartItem.notes + ", " + product.notes : product.notes
              }
            ]
          } else {
            return [
              ...cartItems,
              {
                ...product,
                quantity: product.qty,
                cartItemId: uuidv4(),
              },
            ]
          }

        } else {
          return cartItems.map((item) => 
            item.cartItemId === cartItem.cartItemId
              ? {
                ...item,
                quantity: item.quantity + product.qty,
                qty: item.qty + product.qty,
                totalPrice: (item.qty + product.qty) * item.price,
                notes: item.notes ? item.notes + ", " + product.notes : product.notes
              }
              : item
          );
        }
      }
      // for variant products
    } else {
      // const cartItem = cartItems.filter(
      //   (item) =>
      //     item.id === product.id &&
      //     product.selectedProductColor &&
      //     product.selectedProductColor === item.selectedProductColor &&
      //     product.selectedProductSize &&
      //     product.selectedProductSize === item.selectedProductSize &&
      //     (product.cartItemId ? product.cartItemId === item.cartItemId : true)
      // )[0];

      let cartItemIdx = 0;
      cartItems.filter((item, idx) => {
        let a = [];

        for (let i = 0; i < item.variant.length; i++) {
          const b = item.variant[i]

          a = [...a, b.data_variant[0].id]

          if (JSON.stringify(a) === JSON.stringify(prodVariantID)) {
            cartItemIdx = i
            break;
          }
        }

        if (JSON.stringify(a) === JSON.stringify(prodVariantID)) {
          cartItemIdx = idx
          return cartItemIdx
        }

        return a
      })

      // nanti setelah dapat index nya ambil lagi dari cartitem berdasar indexnya
      const cartItem = cartItems[cartItemIdx]

      let currCartItem;

      if (cartItem) {
        cartItem.variant.forEach(item => {
          currVariantID.push(item.data_variant[0].id)
        })

        if (JSON.stringify(currVariantID) === JSON.stringify(prodVariantID)) {
          isVariantSame = true
        }

        currCartItem = cartItems.filter(
          (item, i) => item.cartItemId !== cartItem.cartItemId
        );
      }

      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.qty,
            cartItemId: uuidv4(),
          },
        ];
      }
      // cek apakah variantnya sama
      else if (
        cartItem !== undefined && cartItem.variant.length === product.variant.length && isVariantSame &&
        cartItem.id === product.id
      ) {
        // ini kalo menu dan variant yang sama ada di cart

        let currVariant = cartItem.variant;
        currVariant.forEach((item, i) => {
          item.data_variant[0].qty += product.qty
        })

        return [
          ...currCartItem,
          {
            ...product,
            qty: cartItem.qty + product.qty,
            quantity: cartItem.quantity + product.qty,
            totalPrice: (cartItem.qty + product.qty) * cartItem.price,
            variant: currVariant,
            cartItemId: cartItem.cartItemId,
            notes: cartItem.notes ? cartItem.notes + ", " + product.notes : product.notes
          },
        ];
      } else {
        // ini kalo menu sama tapi variantnya beda
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.qty,
            cartItemId: uuidv4(),
          },
        ];
      }
    }
  }

  if (action.type === EDIT_ITEM_CART) {
    const cartItemId = product.cartItemId
    let currCarts = cartItems.filter(item => item.cartItemId !== cartItemId)
    currCarts = [...currCarts, product]

    return currCarts
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          (cartItem) => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
      // atasi qty di variant nya juga
      let productVariant = product.variant
      if (productVariant.length > 0) {
        productVariant.forEach(item => {
          item.data_variant.forEach(val => {
            val.qty = product.quantity - 1
          })
        })
      }

      return cartItems.map((item) =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity - 1, qty: item.qty - 1, totalPrice: (item.qty - 1) * item.price, variant: productVariant }
          : item
      );
    }
  }

  if (action.type === INCREASE_QUANTITY) {
    if (product.qty > parseInt(product.data.stock)) {
      console.log('Melebihi stock!');
    } else {
      let productVariant = product.variant
      if (productVariant.length > 0) {
        productVariant.forEach(item => {
          item.data_variant.forEach(val => {
            val.qty = product.quantity + 1
          })
        })
      }

      return cartItems.map((item) =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity + 1, qty: item.qty + 1, totalPrice: (item.qty + 1) * item.price, variant: productVariant }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(
        (cartItem) => cartItem.cartItemId !== product.cartItemId
      );
    return remainingItems(cartItems, product);
  }

  if (action.type === REMOVE_CART) {
    // return cartItems.filter((item) => {
    //   return false;
    // });

    const cartbyID = cartItems.filter(item => item.partnerID !== ls.get("partnerID"))
    return cartbyID
  }

  return state;
};

export default cartReducer;

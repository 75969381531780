export const PAYMENT_METHOD = {
    notPaid: '0',
    OVO: '1',
    gopay: '2',
    dana: '3',
    linkAja: '4',
    tunai: '5',
    sakuku: '6',
    creditCard: '7',
    debitCard: '8',
    qris: '9',
    shopeePay: '10',
    pending: '11',
    piutang: '12',
    dariDP: '13',
    qrisUR: '14',
}

export const isPaymentHandled = (paymentMethod) => (
    paymentMethod === PAYMENT_METHOD.OVO || 
    paymentMethod === PAYMENT_METHOD.gopay || 
    paymentMethod === PAYMENT_METHOD.tunai || 
    paymentMethod === PAYMENT_METHOD.creditCard || 
    paymentMethod === PAYMENT_METHOD.debitCard || 
    paymentMethod === PAYMENT_METHOD.qris || 
    paymentMethod === PAYMENT_METHOD.dana || 
    paymentMethod === PAYMENT_METHOD.linkAja || 
    paymentMethod === PAYMENT_METHOD.shopeePay || 
    paymentMethod === PAYMENT_METHOD.qrisUR
)
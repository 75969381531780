import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MoneyFormat from "./MoneyFormat";
import SkeletonLoader from "./SkeletonLoader";
import ls from "local-storage";
import { StrikethroughS, StrikethroughSOutlined } from "@mui/icons-material";

export default function ProductCard({ productDetails, index, dataFrom, categoryIndex }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState(productDetails.thumbnail)
  const imageLoaded = () => {
      setLoading(false)
  };

  const stockZero = productDetails.stock < 1 && ls.get("sfPartner").allow_override_stock === "0" ? true : false

  return (
    <Grid
      container
      key={index}
      onClick={(e) => {
        if(!stockZero){ 
          navigate("/product-details", {
            state: { details: productDetails, dataFrom: dataFrom, categoryIndex:categoryIndex },
          });
        }
      }}
    >
      <Grid item xs={3}>
        <div
          style={{
            width: 75,
            height: 75,
            borderRadius: 12,
            marginBottom: 15,
            overflow: 'hidden',
          }}
        >
          {productDetails.thumbnail ?
            (
              <div>
                <div style={{ display: loading ? "block" : "none" }}>
                  <SkeletonLoader type="productImageList" />
                </div>
                <div
                  style={{
                    display: loading ? "none" : "block",
                    overflow: 'hidden',
                    width: 75,
                    height: 75,
                  }}
                >
                  <img
                    src={thumbnail}
                    alt="product"
                    style={{
                      borderRadius: 12,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center'
                    }}
                    width={75}
                    onLoad={
                      setTimeout(()=>{
                        setLoading(false)
                      }, 250)
                    }
                    onError={()=>
                      setThumbnail("/assets/img/image-not-found.png")
                    }
                  />
                </div>
              </div>
            )
            :
            (
              <img
                alt=""
                src="/assets/img/image-not-found.png"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: 12
                }}
              />
            )
          }{" "}
        </div>
      </Grid>

      <Grid item xs={6}>
        <Typography>{productDetails.nama}</Typography>
        {
          stockZero ?
          <Typography style={{color: "red"}}>Stok Habis</Typography> :
          <div></div>
        }
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography>
          { 
            productDetails.harga_diskon !== "0" ?
            <div>
              <span style={{textDecoration: 'line-through', fontSize:"14px", color:"#A0A0A0"}}>
                <MoneyFormat value={productDetails.harga} />
              </span> 
            </div>
            :
            <div></div>
          }
          <MoneyFormat value={productDetails.harga_diskon === "0" ? productDetails.harga : productDetails.harga_diskon} />
        </Typography>
      </Grid>
    </Grid>
  );
}

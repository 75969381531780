import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { search } from "../services/Products";
import ProductCard from "../components/ProductCard";
import SkeletonLoader from "../components/SkeletonLoader";
import DefaultContainer from "../components/DefaultContainer";
import DefaultTextField from "../components/DefaultTextField";
import DefaultHeader from "../components/DefaultHeader";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ls from "local-storage";

export default function SearchScreen() {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {}, [location.state]);

  async function handleSearch(keyword) {
    setLoading(true);
    if (keyword.length > 2) {
      await search(keyword, ls.get("partnerID")).then((response) => {
        setProducts(response["menus"] ?? []);
        setLoading(false);
      });
    }

    if(keyword.length < 1){
      setLoading(false)
    }
  }
  let items =
    products &&
    products.map((x, i) => {
      return (
        <div key={i}>
          <ProductCard productDetails={products[i]} index={i} />
        </div>
      );
    });
  return (
    <DefaultContainer loading={loading}>
      <Box>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              background: "#fff",
              padding: 15,
              textAlign: "center",
              position: "sticky",
              top: 0,
            }}
          >
            <DefaultHeader
              title={"Cari Menu"}
              trailing={<SearchOutlinedIcon />}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              background: "#fff",
              padding: "0px 15px",
              position: "sticky",
              top: 60,
            }}
          >
            <DefaultTextField
              variant="outlined"
              label="keyword"
              fullWidth
              autoFocus
              type="search"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: 15 }}>
            {loading ? <SkeletonLoader type={"productList"} /> : items}
          </Grid>
        </Grid>
      </Box>
    </DefaultContainer>
  );
}
